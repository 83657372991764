import { z } from 'zod';

export type DateComparison = z.infer<typeof dateParamsValidator>;

const nullableDateValidator = z.coerce.date().nullish();
const optionalDateValidator = z.union([z.string(), z.date()]).pipe(z.coerce.date()).optional();
const dateParamsValidator = z.object({
	eq: nullableDateValidator,
	ne: nullableDateValidator,
	gt: optionalDateValidator,
	lt: optionalDateValidator,
	gte: optionalDateValidator,
	lte: optionalDateValidator,
});

/** Return parameter validation for a date query parameter that includes all the date comparison operators */
function actionQueryDateParams(): typeof dateParamsValidator {
	return dateParamsValidator;
}

export default actionQueryDateParams;
