export type PossibleTypesResultData = {
	possibleTypes: {
		AccessTokenDirectCreateResult: ['AccessTokenDirectCreateSuccess', 'AuthenticationFailure'];
		AccessTokenRefreshResult: ['AccessTokenRefreshSuccess', 'AuthenticationFailure'];
		AdminUserEntraIdAuthenticateResult: ['AuthenticationFailure', 'AuthenticationSuccess'];
		AuditLog: ['UserAuditLog', 'WorkspaceRoleAuditLog'];
		BaseCustomerCardTokenDetail: [
			'GatewayCustomerCardTokenDetail',
			'Shift4CustomerCardTokenDetail',
		];
		BaseIntegrationLayerResponse: [
			'CapturePaymentResponse',
			'CaptureSignatureResponse',
			'CardAuthorizationResponse',
			'CardReferencedRefundResponse',
			'GatewayErrorResponse',
			'ReadCustomerConfirmationResponse',
			'ReadGiftCardResponse',
			'VoidResponse',
		];
		CapturePaymentResult: ['CapturePaymentResponse', 'GatewayErrorResponse'];
		CaptureSignatureResult: ['CaptureSignatureResponse', 'GatewayErrorResponse'];
		CardAuthorizationResult: ['CardAuthorizationResponse', 'GatewayErrorResponse'];
		CardForcedRefundResult: ['CardForcedRefundResponse', 'GatewayErrorResponse'];
		CardReferencedRefundResult: ['CardReferencedRefundResponse', 'GatewayErrorResponse'];
		CardVoidResult: ['GatewayErrorResponse', 'VoidResponse'];
		CustomerCardTokenDetail: ['GatewayCustomerCardTokenDetail', 'Shift4CustomerCardTokenDetail'];
		FinancingAccountSearchResult: ['FinancingAccountSearchResponse', 'FinancingErrorResponse'];
		FinancingAuthorizationAdjustmentResult: [
			'FinancingAuthorizationAdjustmentResponse',
			'FinancingErrorResponse',
		];
		FinancingAuthorizationResult: ['FinancingAuthorizationResponse', 'FinancingErrorResponse'];
		FinancingAuthorizationReversalResult: [
			'FinancingAuthorizationReversalResponse',
			'FinancingErrorResponse',
		];
		FinancingCreditApplicationResult: [
			'FinancingCreditApplicationResponse',
			'FinancingErrorResponse',
		];
		FinancingSettlementResult: ['FinancingErrorResponse', 'FinancingSettlementResponse'];
		Item: ['Kit', 'Package', 'Product'];
		OpenOrder: ['OpenQuote', 'OpenSalesOrder'];
		OpenSalesOrderExceptionable: ['InvalidTransaction', 'OpenSalesOrder'];
		OrderCashPaymentResult: ['OrderCashPaymentErrorResponse', 'OrderCashPaymentSuccessResponse'];
		OrderCheckPaymentResult: ['OrderCheckPaymentErrorResponse', 'OrderCheckPaymentSuccessResponse'];
		PaymentTaskResult: [
			'PaymentTaskCaptureSignatureResult',
			'PaymentTaskCardAuthorizationResult',
			'PaymentTaskCardForcedRefundResult',
			'PaymentTaskReadCustomerConfirmationResult',
			'PaymentTaskReadGiftCardResult',
		];
		Protection: ['ExtendedWarranty', 'ProtectionPlan'];
		ProtectionPlanTableRow: [
			'ProtectionPlanFixedPriceFixedCostTableRow',
			'ProtectionPlanFixedPricePercentageCostTableRow',
			'ProtectionPlanPercentagePriceFixedCostTableRow',
			'ProtectionPlanPercentagePricePercentageCostTableRow',
		];
		QueryResult: [
			'ActivityCommentsQueryResult',
			'ActivityQueryResult',
			'ActivityStageQueryResult',
			'AdminUserQueryResult',
			'AdyenConfiguredStorisLocationQueryResult',
			'AllActiveProtectionPlansQueryResult',
			'AppQueryResult',
			'AppRegistrationQueryResult',
			'BrandQueryResult',
			'CartQueryResult',
			'CollectionQueryResult',
			'CompletedSalesOrderQueryResult',
			'DeliveryChargeOverrideReasonQueryResult',
			'ElavonConfiguredStorisLocationQueryResult',
			'EmvTerminalQueryResult',
			'EntraIdConfigurationQueryResult',
			'ExceptionReasonQueryResult',
			'FiservConfiguredStorisLocationQueryResult',
			'HandlingMethodQueryResult',
			'ItemLocationInventoriesResult',
			'ItemQueryResult',
			'ItemRelatedItemsResult',
			'LocationQueryResult',
			'OpenSalesOrderQueryResult',
			'OpportunityCommentsQueryResult',
			'OpportunityProjectTypeQueryResult',
			'OpportunityQueryResult',
			'OpportunityStageQueryResult',
			'OrderCommentQueryResult',
			'ProductCategoryQueryResult',
			'ProductGroupQueryResult',
			'PurchaseOrderItemQueryResult',
			'PurchaseOrderQueryResult',
			'PurchaseStatusQueryResult',
			'RegionQueryResult',
			'RelationshipQueryResult',
			'SalespersonQueryResult',
			'SuggestionQueryResult',
			'UserAuditLogQueryResult',
			'UserAuthenticationLogQueryResult',
			'UserGroupQueryResult',
			'UserQueryResult',
			'VendorQueryResult',
			'WorkspaceQueryResult',
			'WorkspaceRoleAuditLogQueryResult',
			'WorkspaceRoleQueryResult',
		];
		Quote: ['OpenQuote'];
		ReadCustomerConfirmationResult: ['GatewayErrorResponse', 'ReadCustomerConfirmationResponse'];
		ReadGiftCardResult: ['GatewayErrorResponse', 'ReadGiftCardResponse'];
		SalesDiscount: ['SalesDiscountAmount', 'SalesDiscountPercentage'];
		SalesOrder: ['CanceledSalesOrder', 'CompletedSalesOrder', 'OpenSalesOrder'];
		SalesOrderLine: ['CompletedSalesOrderLine', 'OpenSalesOrderLine'];
		SearchDuplicatesResult: ['SearchDuplicatesFailure', 'SearchDuplicatesSuccess'];
		Source: ['SourceAdmin', 'SourceApp', 'SourceUnauthenticated', 'SourceUser'];
		TakeOrderCreditCardAuthorizationToCaptureResult: [
			'GatewayErrorResponse',
			'TakeOrderCreditCardAuthorizationToCaptureResponse',
		];
		TakeOrderCreditCardPaymentWithTokenResult: [
			'GatewayErrorResponse',
			'TakeOrderCreditCardPaymentWithTokenResponse',
		];
		TaskResult: ['TaskImportResult', 'TaskSyncResult'];
		UserAddWorkspaceRoleResult: ['User', 'UserAddWorkspaceRoleError'];
		UserAuthenticationLog: ['UserAuthenticationLogFailure', 'UserAuthenticationLogSuccess'];
		UserEntraIdAuthenticateResult: ['AuthenticationFailure', 'AuthenticationSuccess'];
		UserPasswordAuthenticateResult: ['AuthenticationFailure', 'AuthenticationSuccess'];
		UserRemoveWorkspaceRoleResult: ['User', 'UserRemoveWorkspaceRoleError'];
	};
};
const result: PossibleTypesResultData = {
	possibleTypes: {
		AccessTokenDirectCreateResult: ['AccessTokenDirectCreateSuccess', 'AuthenticationFailure'],
		AccessTokenRefreshResult: ['AccessTokenRefreshSuccess', 'AuthenticationFailure'],
		AdminUserEntraIdAuthenticateResult: ['AuthenticationFailure', 'AuthenticationSuccess'],
		AuditLog: ['UserAuditLog', 'WorkspaceRoleAuditLog'],
		BaseCustomerCardTokenDetail: [
			'GatewayCustomerCardTokenDetail',
			'Shift4CustomerCardTokenDetail',
		],
		BaseIntegrationLayerResponse: [
			'CapturePaymentResponse',
			'CaptureSignatureResponse',
			'CardAuthorizationResponse',
			'CardReferencedRefundResponse',
			'GatewayErrorResponse',
			'ReadCustomerConfirmationResponse',
			'ReadGiftCardResponse',
			'VoidResponse',
		],
		CapturePaymentResult: ['CapturePaymentResponse', 'GatewayErrorResponse'],
		CaptureSignatureResult: ['CaptureSignatureResponse', 'GatewayErrorResponse'],
		CardAuthorizationResult: ['CardAuthorizationResponse', 'GatewayErrorResponse'],
		CardForcedRefundResult: ['CardForcedRefundResponse', 'GatewayErrorResponse'],
		CardReferencedRefundResult: ['CardReferencedRefundResponse', 'GatewayErrorResponse'],
		CardVoidResult: ['GatewayErrorResponse', 'VoidResponse'],
		CustomerCardTokenDetail: ['GatewayCustomerCardTokenDetail', 'Shift4CustomerCardTokenDetail'],
		FinancingAccountSearchResult: ['FinancingAccountSearchResponse', 'FinancingErrorResponse'],
		FinancingAuthorizationAdjustmentResult: [
			'FinancingAuthorizationAdjustmentResponse',
			'FinancingErrorResponse',
		],
		FinancingAuthorizationResult: ['FinancingAuthorizationResponse', 'FinancingErrorResponse'],
		FinancingAuthorizationReversalResult: [
			'FinancingAuthorizationReversalResponse',
			'FinancingErrorResponse',
		],
		FinancingCreditApplicationResult: [
			'FinancingCreditApplicationResponse',
			'FinancingErrorResponse',
		],
		FinancingSettlementResult: ['FinancingErrorResponse', 'FinancingSettlementResponse'],
		Item: ['Kit', 'Package', 'Product'],
		OpenOrder: ['OpenQuote', 'OpenSalesOrder'],
		OpenSalesOrderExceptionable: ['InvalidTransaction', 'OpenSalesOrder'],
		OrderCashPaymentResult: ['OrderCashPaymentErrorResponse', 'OrderCashPaymentSuccessResponse'],
		OrderCheckPaymentResult: ['OrderCheckPaymentErrorResponse', 'OrderCheckPaymentSuccessResponse'],
		PaymentTaskResult: [
			'PaymentTaskCaptureSignatureResult',
			'PaymentTaskCardAuthorizationResult',
			'PaymentTaskCardForcedRefundResult',
			'PaymentTaskReadCustomerConfirmationResult',
			'PaymentTaskReadGiftCardResult',
		],
		Protection: ['ExtendedWarranty', 'ProtectionPlan'],
		ProtectionPlanTableRow: [
			'ProtectionPlanFixedPriceFixedCostTableRow',
			'ProtectionPlanFixedPricePercentageCostTableRow',
			'ProtectionPlanPercentagePriceFixedCostTableRow',
			'ProtectionPlanPercentagePricePercentageCostTableRow',
		],
		QueryResult: [
			'ActivityCommentsQueryResult',
			'ActivityQueryResult',
			'ActivityStageQueryResult',
			'AdminUserQueryResult',
			'AdyenConfiguredStorisLocationQueryResult',
			'AllActiveProtectionPlansQueryResult',
			'AppQueryResult',
			'AppRegistrationQueryResult',
			'BrandQueryResult',
			'CartQueryResult',
			'CollectionQueryResult',
			'CompletedSalesOrderQueryResult',
			'DeliveryChargeOverrideReasonQueryResult',
			'ElavonConfiguredStorisLocationQueryResult',
			'EmvTerminalQueryResult',
			'EntraIdConfigurationQueryResult',
			'ExceptionReasonQueryResult',
			'FiservConfiguredStorisLocationQueryResult',
			'HandlingMethodQueryResult',
			'ItemLocationInventoriesResult',
			'ItemQueryResult',
			'ItemRelatedItemsResult',
			'LocationQueryResult',
			'OpenSalesOrderQueryResult',
			'OpportunityCommentsQueryResult',
			'OpportunityProjectTypeQueryResult',
			'OpportunityQueryResult',
			'OpportunityStageQueryResult',
			'OrderCommentQueryResult',
			'ProductCategoryQueryResult',
			'ProductGroupQueryResult',
			'PurchaseOrderItemQueryResult',
			'PurchaseOrderQueryResult',
			'PurchaseStatusQueryResult',
			'RegionQueryResult',
			'RelationshipQueryResult',
			'SalespersonQueryResult',
			'SuggestionQueryResult',
			'UserAuditLogQueryResult',
			'UserAuthenticationLogQueryResult',
			'UserGroupQueryResult',
			'UserQueryResult',
			'VendorQueryResult',
			'WorkspaceQueryResult',
			'WorkspaceRoleAuditLogQueryResult',
			'WorkspaceRoleQueryResult',
		],
		Quote: ['OpenQuote'],
		ReadCustomerConfirmationResult: ['GatewayErrorResponse', 'ReadCustomerConfirmationResponse'],
		ReadGiftCardResult: ['GatewayErrorResponse', 'ReadGiftCardResponse'],
		SalesDiscount: ['SalesDiscountAmount', 'SalesDiscountPercentage'],
		SalesOrder: ['CanceledSalesOrder', 'CompletedSalesOrder', 'OpenSalesOrder'],
		SalesOrderLine: ['CompletedSalesOrderLine', 'OpenSalesOrderLine'],
		SearchDuplicatesResult: ['SearchDuplicatesFailure', 'SearchDuplicatesSuccess'],
		Source: ['SourceAdmin', 'SourceApp', 'SourceUnauthenticated', 'SourceUser'],
		TakeOrderCreditCardAuthorizationToCaptureResult: [
			'GatewayErrorResponse',
			'TakeOrderCreditCardAuthorizationToCaptureResponse',
		],
		TakeOrderCreditCardPaymentWithTokenResult: [
			'GatewayErrorResponse',
			'TakeOrderCreditCardPaymentWithTokenResponse',
		],
		TaskResult: ['TaskImportResult', 'TaskSyncResult'],
		UserAddWorkspaceRoleResult: ['User', 'UserAddWorkspaceRoleError'],
		UserAuthenticationLog: ['UserAuthenticationLogFailure', 'UserAuthenticationLogSuccess'],
		UserEntraIdAuthenticateResult: ['AuthenticationFailure', 'AuthenticationSuccess'],
		UserPasswordAuthenticateResult: ['AuthenticationFailure', 'AuthenticationSuccess'],
		UserRemoveWorkspaceRoleResult: ['User', 'UserRemoveWorkspaceRoleError'],
	},
};
export default result;
