import { Box } from '@storis/app_common.ui/components';
import type { BoxProps } from '@storis/app_common.ui/components';

export interface ContentProps extends BoxProps {
	/**
	 * Disable horizontal padding
	 *
	 * (default: false)
	 */
	disableGutters?: boolean;
	/**
	 * Disable vertical padding
	 *
	 * (default: false)
	 */
	disablePadding?: boolean;
}

/** Props of [Box](https://mui.com/material-ui/api/box/) are also available. */
const Content = (props: ContentProps) => {
	const { children, disableGutters = false, disablePadding = false, sx, ...other } = props;
	return (
		<Box
			sx={{
				px: disableGutters ? 0 : { xs: 2, sm: 3 },
				py: disablePadding ? 0 : { xs: 2, sm: 3 },
				...sx,
			}}
			{...other}
		>
			{children}
		</Box>
	);
};

export default Content;
