import type { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import MuiDialog from '@mui/material/Dialog';
import { forwardRef } from 'react';

/** @knipignore - continue exporting the props type */
export interface DialogProps
	extends Omit<
		MuiDialogProps,
		'BackdropComponent' | 'PaperProps' | 'TransitionComponent' | 'TransitionProps'
	> {
	/**
	 * @deprecated `BackdropComponent` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slots.backdrop` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/dialog/#props
	 */
	BackdropComponent?: never;
	/**
	 * @deprecated `PaperProps` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slotProps.paper` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/dialog/#props
	 */
	PaperProps?: never;
	/**
	 * @deprecated `TransitionComponent` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slots.transition` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/dialog/#props
	 */
	TransitionComponent?: never;
	/**
	 * @deprecated `TransitionProps` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slotProps.transition` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/dialog/#props
	 */
	TransitionProps?: never;
}

export const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => (
	<MuiDialog {...props} ref={ref} />
));
