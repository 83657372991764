import {
	Box,
	Button,
	CircularProgress,
	styled,
	Typography,
} from '@storis/app_common.ui/components';
import type React from 'react';
import type { ContentProps } from '../Content';
import { Content } from '../Content';

const StyledContent = styled(Content)({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
});

export interface EmptyContentViewProps extends ContentProps {
	/**
	 * The text to display on the button.
	 *
	 * (default: 'Try Again')
	 */
	buttonText?: string;
	/**
	 * The content to display.
	 *
	 * (default: null)
	 */
	children?: React.ReactNode;
	/**
	 * If true, the element will be condensed by rendering with `minimum-height: 'initial'`
	 *
	 * Otherwise, it will be rendered with a `minimum-height` equal to the majority of the viewport height.
	 *
	 * (default: false)
	 */
	dense?: boolean;
	/** If specified, this will be applied as the `id` of the content, establish an accessible name for the element. */
	labelId?: string;
	/**
	 * If true, the button will be replaced with a progress indicator.
	 *
	 * (default: false)
	 */
	loading?: boolean;
	/** If specified, a button that invokes this function will be presented with `buttonText`. */
	onClick?: () => void;
}

const EmptyContentView = (props: EmptyContentViewProps) => {
	const {
		buttonText = 'Try Again',
		children = null,
		dense = false,
		labelId,
		onClick,
		loading = false,
		sx,
		...other
	} = props;

	return (
		<StyledContent {...other} sx={{ minHeight: dense ? 'initial' : '70vh', ...sx }}>
			<Box
				sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
				aria-labelledby={labelId}
			>
				<Typography id={labelId} align="center" variant="h6" color="textSecondary">
					{children}
				</Typography>
				{onClick &&
					(loading ? (
						// match the vertical size of the button. 0.875rem font size * 1.75 line spacing + 12px padding
						<CircularProgress size="calc(1.53125rem + 12px)" />
					) : (
						<Button color="primary" onClick={onClick} aria-describedby={labelId}>
							{buttonText}
						</Button>
					))}
			</Box>
		</StyledContent>
	);
};

export default EmptyContentView;
