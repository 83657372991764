import type { SnackbarProps as MuiSnackbarProps } from '@mui/material/Snackbar';
import MuiSnackbar from '@mui/material/Snackbar';
import { forwardRef } from 'react';

/** @knipignore - continue exporting the props type */
export interface SnackbarProps
	extends Omit<
		MuiSnackbarProps,
		'ClickAwayListenerProps' | 'ContentProps' | 'TransitionComponent' | 'TransitionProps'
	> {
	/**
	 * @deprecated `ClickAwayListenerProps` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slotProps.clickAwayListener` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/snackbar/#props
	 */
	ClickAwayListenerProps?: never;
	/**
	 * @deprecated `ContentProps` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slotProps.content` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/snackbar/#props
	 */
	ContentProps?: never;
	/**
	 * @deprecated `TransitionComponent` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slots.transition` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/snackbar/#props
	 */
	TransitionComponent?: never;
	/**
	 * @deprecated `TransitionProps` is deprecated in v6 and will be removed in v7.
	 *
	 *   It is recommended to use the `slotProps.transition` prop instead.
	 *
	 *   See: https://mui.com/material-ui/api/snackbar/#props
	 */
	TransitionProps?: never;
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>((props, ref) => (
	<MuiSnackbar {...props} ref={ref} />
));
